import React, { useState, useEffect } from "react";

const defaultOptions = {
  threshold: 0,
  triggerOnce: true,
};

export function useInView(ref, options) {
  const [isIntersecting, setIntersecting] = useState(false);
  const { threshold, triggerOnce, id } = { ...defaultOptions, ...options };

  useEffect(() => {
    const hasSupport = "IntersectionObserver" in window;
    let observer;

    if (hasSupport) {
      observer = new IntersectionObserver(
        ([entry]) => {
          if (triggerOnce && entry.intersectionRatio > 0) {
            setIntersecting(entry.isIntersecting);
            if (entry.isIntersecting) {
              observer.disconnect();
            }
          } else {
            setIntersecting(entry.isIntersecting);
          }
        },
        { threshold }
      );
      observer.observe(ref.current);
    } else {
      setIntersecting(true);
    }

    return () => {
      if (hasSupport) {
        observer.disconnect();
      }
    };
  }, [threshold, triggerOnce, ref, id]);

  return isIntersecting;
}
